.login-container {
  padding: 2rem;
  /* width: 60dvh; */
  max-width: 500px;
}

.login {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
  overflow: auto;
}

.login__container {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 450px;
  max-width: 90vw;
}

.login__title {
  text-align: center;
  margin-bottom: 1rem;
}

.login__description {
  text-align: center;
  margin-bottom: 0.5rem;
}

.login__form {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
}

.login__input-group {
  display: flex;
}

.login__input {
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 1rem;
  font-size: 1rem;
}

.login__input--email {
  flex-grow: 1;
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.login__input--domain {
  width: auto;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  cursor: pointer;
  background-color: var(--primary);
  font-size: 14px;
}

.login__input--password {
  flex-grow: 1;
}

.login__aux-options {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

.login__checkbox {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.login__checkbox input {
  margin-right: 0.5rem;
}

.login__link {
  color: var(--danger);
  text-decoration: none;
}

.login__footer {
  margin-top: 1.8rem;
  display: flex;
  justify-content: space-around;
}

.login__link--register {
  font-weight: bold;
}

.login__button {
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 4px;
  background-color: #008c45;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login__button:hover {
  /* background-color: #a00; */
}

.login__input-group {
  display: flex;
  position: relative;
}

.login__input--password {
  flex-grow: 1;
  padding-right: 2.5rem;
}

.login__toggle-password {
  position: absolute;
  right: 0.5rem;
  top: 0.75rem;
  background: none;
  border: none;
  cursor: pointer;
}

.login__error-message {
  color: red;
  font-size: small;
  text-align: center;
}

.login-capital {
  border-radius: 50%;
  height: 100%;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fed141;
  font-size: large;
}

.signout {
  z-index: 999;
  position: fixed;
  right: 10%;
  height: 65px;
  padding: 5px;
  top: 0;
}

#g_id_signout {
  height: 40px;
  gap: 10px;
  border-radius: 25px 15px 15px 25px;
  padding: 0px 25px 0px 0px;
  box-shadow: 0px 2px 5px #165e2e;
  border: none;
  cursor: pointer;
}

/* ----------------------- */
@media (max-width: 768px) and (orientation: portrait) {
  .login__container {
    padding: 2rem;
  }

  .login__input-group {
    flex-direction: column;
  }

  .login__input--email {
    border-right: 1px solid #ccc;
  }
  .signout {
    right: 5%;
  }
}

@media (max-width: 768px) and (orientation: landscape) {
  .login__container {
    padding: 0.5rem;
  }

  .login__footer {
    flex-direction: row;
    justify-content: space-between;
  }

  .login__button {
    width: auto;
  }
}
